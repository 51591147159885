<template>
    <BannerBlue title="Catálogos automotriz" />
    <div class="container">
        <div class="contenedor-externo">
            <div v-for="(imagen, index) in catalogos" :key="index" class="contenedor-interno">
                <div class="title-cat mb-1">
                    <a class="img-cat" target="_blank">
                        <h4 style="bottom:5px;">{{ imagen.title }}</h4>
                    </a>
                </div>
                <a class="img-cat" id="show-button" target="_blank" @click="verModal(imagen.pdf)" style="cursor: pointer;">
                    <img loading="lazy" :src="imagen.image" :alt="imagen.title" />
                </a>
            </div>
        </div>
    </div>
    <div>
        <div v-if="isDialogOpen" class="backdrop" @click="closeDialog()">
            <div class="dialog" @click.stop>
                <button class="close-btn" @click="closeDialog()">✖</button>
                
                <embed :src="urlpdf" type="application/pdf" width="100%" height="100%" style="padding: 26px;"/>
            </div>
        </div>
    </div>
</template>

<script>
import BannerBlue from '@/components/BannerBlue.vue';
import { ref } from 'vue';
export default {
    props: {},
    components: {
        BannerBlue,
    },
    data() {
        return {
            layout: ref('grid'),
            catalogosOrdenados: ref([]),
            isDialogOpen: false,
            urlpdf: "",
            catalogos: [
                {
                    title: 'bgs',
                    image: 'https://files.proveedorferretero.net/img/2025/catalogo/bgs.webp',
                    pdf: 'https://files.proveedorferretero.net/pdf/2025/catalogos/bgs-2024.pdf',
                    imp: 10
                },
                {
                    title: 'bosch herramienta electrica',
                    image: 'https://files.proveedorferretero.net/img/2025/catalogo/bosch-2025.webp',
                    pdf: 'https://files.proveedorferretero.net/pdf/2025/catalogos/bosch-catalogo-2025.pdf',
                    imp: 10
                },
                {
                    title: 'urrea',
                    image: 'https://files.proveedorferretero.net/img/2025/catalogo/urrea.webp',
                    pdf: 'https://files.proveedorferretero.net/pdf/2025/catalogos/Catalogo-URREA-2024.pdf',
                    imp: 10
                },
                {
                    title: 'weston',
                    image: 'https://files.proveedorferretero.net/img/2025/catalogo/west.webp',
                    pdf: 'https://files.proveedorferretero.net/pdf/2025/catalogos/Catalogo-Weston.pdf',
                    imp: 10
                },
                {
                    title: 'dewalt herramienta electrica',
                    image: 'https://files.proveedorferretero.net/img/2025/catalogo/dewalt.webp',
                    pdf: 'https://files.proveedorferretero.net/pdf/2025/catalogos/dewalt-herramienta-electrica.pdf',
                    imp: 10
                },

                {
                    title: 'knipex alicates',
                    image: 'https://files.proveedorferretero.net/img/2025/catalogo/knipex-alicates.webp',
                    pdf: 'https://files.proveedorferretero.net/pdf/2025/catalogos/knipex-alicates-2017.pdf',
                    imp: 10
                },
                {
                    title: 'knipex gama electrica',
                    image: 'https://files.proveedorferretero.net/img/2025/catalogo/knipex-electrica.webp',
                    pdf: 'https://files.proveedorferretero.net/pdf/2025/catalogos/knipex-gama-electrica.pdf',
                    imp: 10
                },
                {
                    title: 'karcher',
                    image: 'https://files.proveedorferretero.net/img/2025/catalogo/karcher.webp',
                    pdf: 'https://files.proveedorferretero.net/pdf/2025/catalogos/karcher-2025.pdf',
                    imp: 10
                },
                {
                    title: 'makita',
                    image: 'https://files.proveedorferretero.net/img/2025/catalogo/makita.webp',
                    pdf: 'https://files.proveedorferretero.net/pdf/2025/catalogos/makita-general-2025.pdf',
                    imp: 10
                },
                {
                    title: 'Mikels',
                    image: 'https://files.proveedorferretero.net/img/2025/catalogo/mikels.webp',
                    pdf: 'https://files.proveedorferretero.net/pdf/2025/catalogos/Mikels-2025.pdf',
                    imp: 10
                },
                {
                    title: 'milwaukee accesorios',
                    image: 'https://files.proveedorferretero.net/img/2025/catalogo/milwaukee-accesorios.webp',
                    pdf: 'https://files.proveedorferretero.net/pdf/2025/catalogos/milwaukee-acc-2025l.pdf',
                    imp: 10
                },
                {
                    title: 'milwaukee ppe',
                    image: 'https://files.proveedorferretero.net/img/2025/catalogo/milwaukee-ppe.webp',
                    pdf: 'https://files.proveedorferretero.net/pdf/2025/catalogos/milwaukee-safe-safe.pdf',
                    imp: 10
                },
                {
                    title: 'snap on industrial',
                    image: 'https://files.proveedorferretero.net/img/2025/catalogo/snapon-industrial.webp',
                    pdf: 'https://files.proveedorferretero.net/pdf/2025/catalogos/snap-on-industrial-2025.pdf',
                    imp: 10
                },
                
            ]
        };
    },
    mounted: function () {
        this.ordenarCatalogos();
    },
    methods: {
        ordenarCatalogos() {
            // Ordenar primero por "imp" y luego por "title"
            this.catalogos.sort((a, b) => {
                if (a.imp !== b.imp) {
                    return a.imp - b.imp;
                } else {
                    return a.title.localeCompare(b.title);
                }
            });
        },
        verModal(pdf) {
            this.isDialogOpen = true;
            this.urlpdf = pdf;
        },
        closeDialog() {
            this.isDialogOpen = false;
            this.urlpdf = "";
        }
    },
}
</script>

<style scoped>
/* Fondo semitransparente */
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
}

/* Contenedor del diálogo */
.dialog {
    background: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    position: relative;
    width: 80%;
    height: 95%;
}
@media only screen and (max-width: 600px) {
    .dialog {
        background: white;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
        position: relative;
        width: 100%;
        height: 100%;
    }
}
/* Botón de cerrar */
.close-btn {
    position: absolute;
    top: 1px;
    right: 1px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

h4 {
    line-height: 40px;
}

h4:hover {
    color: #ffbf00;
}

img:hover {
    box-shadow: 0 0 20px #ffbf00;
}

.contenedor-externo {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.contenedor-interno {
    margin-right: 10px;
    /* Espacio entre los contenedores internos, ajusta según tu preferencia */
    max-width: 400px;
}

.title-cat {
    height: 80px;
    display: flex;
    text-align: start;
    align-items: flex-end;
}

.title-cat a h3 {
    color: #000;
}

.title-cat img {
    box-shadow: none;
}

.contenedor-interno a img:hover~.title-cat a h3 {
    color: #ffbf00;
}

.title-cat a h3:hover {
    color: #ffbf00;
}

img {
    width: 100%;
    /* Asegura que las imágenes ocupen todo el ancho del contenedor interno */
    height: auto;
    /* Permite que la altura se ajuste proporcionalmente al ancho */
    border-radius: 10px;
}

@media (max-width: 1200px) {
    .img {
        width: 80%;
    }

    .title-cat {
        padding-left: 15px;
    }
}
</style>