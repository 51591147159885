<template>
    <BannerBlue title="Catálogos seguridad" />
    <div class="container">
        <div class="contenedor-externo">
            <div v-for="(imagen, index) in catalogos" :key="index" class="contenedor-interno">
                <div class="title-cat mb-1">
                    <a class="img-cat" target="_blank">
                        <h4 style="bottom:5px;">{{ imagen.title }}</h4>
                    </a>
                </div>
                <a class="img-cat" id="show-button" target="_blank" @click="verModal(imagen.pdf)" style="cursor: pointer;">
                    <img loading="lazy" :src="imagen.image" :alt="imagen.title" />
                </a>
            </div>
        </div>
    </div>
    <div>
        <div v-if="isDialogOpen" class="backdrop" @click="closeDialog()">
            <div class="dialog" @click.stop>
                <button class="close-btn" @click="closeDialog()">✖</button>
                
                <embed :src="urlpdf" type="application/pdf" width="100%" height="100%" style="padding: 26px;"/>
            </div>
        </div>
    </div>
</template>

<script>
import BannerBlue from '@/components/BannerBlue.vue';
import { ref } from 'vue';
export default {
    props: {},
    components: {
        BannerBlue,
    },
    data() {
        return {
            layout: ref('grid'),
            catalogosOrdenados: ref([]),
            isDialogOpen: false,
            urlpdf: "",
            catalogos: [
                {
                    title: 'LIBUS',
                    image: 'https://files.proveedorferretero.net/img/2025/catalogo/libus.webp',
                    pdf: 'https://files.proveedorferretero.net/pdf/2025/catalogos/catalogo-libus-2024.pdf',
                    imp: 10
                },
                {
                    title: 'libus protect',
                    image: 'https://files.proveedorferretero.net/img/2025/catalogo/protect.webp',
                    pdf: 'https://files.proveedorferretero.net/pdf/2025/catalogos/catalogo-libus-bls-2024-2025.pdf',
                    imp: 10
                },
                {
                    title: 'jyrsa',
                    image: 'https://files.proveedorferretero.net/img/2025/catalogo/jyrsa.webp',
                    pdf: 'https://files.proveedorferretero.net/pdf/2025/catalogos/guia-rapida-eco-jyrsa-2024.pdf',
                    imp: 10
                },
                {
                    title: 'safe-fit',
                    image: 'https://files.proveedorferretero.net/img/2025/catalogo/safe-fit.webp',
                    pdf: 'https://files.proveedorferretero.net/pdf/2025/catalogos/catalogo+safefit+2025.pdf',
                    imp: 10
                },
                {
                    title: 'abus',
                    image: 'https://files.proveedorferretero.net/img/2025/catalogo/abus-2024.webp',
                    pdf: 'https://files.proveedorferretero.net/pdf/2025/catalogos/abus-2024.pdf',
                    imp: 10
                }
            ]
        };
    },
    mounted: function () {
        this.ordenarCatalogos();
    },
    methods: {
        ordenarCatalogos() {
            // Ordenar primero por "imp" y luego por "title"
            this.catalogos.sort((a, b) => {
                if (a.imp !== b.imp) {
                    return a.imp - b.imp;
                } else {
                    return a.title.localeCompare(b.title);
                }
            });
        },
        verModal(pdf) {
            this.isDialogOpen = true;
            this.urlpdf = pdf;
        },
        closeDialog() {
            this.isDialogOpen = false;
            this.urlpdf = "";
        }
    },
}
</script>

<style scoped>
/* Fondo semitransparente */
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
}

/* Contenedor del diálogo */
.dialog {
    background: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    position: relative;
    width: 80%;
    height: 95%;
}
@media only screen and (max-width: 600px) {
    .dialog {
        background: white;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
        position: relative;
        width: 100%;
        height: 100%;
    }
}
/* Botón de cerrar */
.close-btn {
    position: absolute;
    top: 1px;
    right: 1px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

h4 {
    line-height: 40px;
}

h4:hover {
    color: #ffbf00;
}

img:hover {
    box-shadow: 0 0 20px #ffbf00;
}

.contenedor-externo {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.contenedor-interno {
    margin-right: 10px;
    /* Espacio entre los contenedores internos, ajusta según tu preferencia */
    max-width: 400px;
}

.title-cat {
    height: 80px;
    display: flex;
    text-align: start;
    align-items: flex-end;
}

.title-cat a h3 {
    color: #000;
}

.title-cat img {
    box-shadow: none;
}

.contenedor-interno a img:hover~.title-cat a h3 {
    color: #ffbf00;
}

.title-cat a h3:hover {
    color: #ffbf00;
}

img {
    width: 100%;
    /* Asegura que las imágenes ocupen todo el ancho del contenedor interno */
    height: auto;
    /* Permite que la altura se ajuste proporcionalmente al ancho */
    border-radius: 10px;
}

@media (max-width: 1200px) {
    .img {
        width: 80%;
    }

    .title-cat {
        padding-left: 15px;
    }
}
</style>